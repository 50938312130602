import React, { useState, useEffect } from "react";
import { Box, TextField, Chip, Card, IconButton } from "@mui/material";
import { useLocation } from "react-router-dom";
import Parser from "html-react-parser";
import axios from "axios";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { renderFileIcon } from "../helpers/renderFileIcon";
import CloseIcon from "@mui/icons-material/Close";
import PreviewImages from "../components/Customs/PreviewImages";
// import { Helmet } from "react-helmet-async";

const ViewPublicNote = () => {
  const location = useLocation();
  const [note, setNote] = useState(null);
  const [selectedColor, setSelectedColor] = useState("#ffffff");
  const [selectedTags, setSelectedTags] = useState([]);
  const [existingAttachments, setexistingsAttachments] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isViewOpen, setIsViewOpen] = useState(false);

  const imageList = existingAttachments?.filter((att) => att.type === "image");

  useEffect(() => {
    const noteId = location.pathname.split("/")[3];
    fetchNoteDetail(noteId);
  }, [location]);

  const fetchNoteDetail = async (noteId) => {
    try {
      const response = await axios.get(`/notes/public/${noteId}`);
      if (response?.data?.isLocked || response?.data?.isDeleted) {
        setNote({ title: response?.data?.title });
      }
      // document.title = response?.data?.note?.title;
      const fetchedNote = response?.data?.note;
      setNote({ ...fetchedNote, details: Parser(fetchedNote?.details) });
      setSelectedColor(fetchedNote.color);
      setexistingsAttachments(fetchedNote.attachments);
      setSelectedTags(fetchedNote.tags);
    } catch (error) {
      console.error("Failed to fetch note:", error);
    }
  };

  const getLuminance = (color) => {
    if (!color || color.length === 0) return null;
    let r, g, b;
    if (color.startsWith("#")) {
      const hex = color.substring(1);
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
    } else {
      const rgb = color.match(/\d+/g);
      r = parseInt(rgb[0], 10);
      g = parseInt(rgb[1], 10);
      b = parseInt(rgb[2], 10);
    }

    return 0.299 * r + 0.587 * g + 0.114 * b;
  };

  const setTextColor = (color) => {
    if (!color || color.length === 0) return null;
    const isDarkColor = getLuminance(color) < 128;
    return isDarkColor ? "#fff" : "#000";
  };

  const setChipColor = (color) => {
    const isDarkColor = getLuminance(color) < 128;
    return isDarkColor ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.1)";
  };

  const truncateFileName = (fileName) => {
    const maxLength = 15;
    return fileName.length > maxLength ? fileName.substring(0, maxLength) + "..." : fileName;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        backgroundColor: selectedColor,
        padding: 0,
        color: setTextColor(selectedColor),
        overflowY: "auto",
        position: "relative",
        transition: "background-color 0.3s ease",
      }}
    >
      {/* {note && (
        <Helmet>
          <title>{note?.title}</title>
          <meta name="description" content={note?.title} />
          <meta property="og:title" content={note?.title} />
          {existingAttachments.length > 0 && (
            <meta property="og:image" content={existingAttachments[0]?.url} />
          )}
          <meta property="og:description" content={note?.details} />
        </Helmet>
      )} */}
      <Box
        sx={{
          marginTop: 2,
          width: "90%",
          maxWidth: 600,
          textAlign: "center",
        }}
      >
        <TextField
          variant="standard"
          multiline
          fullWidth
          placeholder="Title"
          value={note ? note.title : ""}
          InputProps={{
            style: {
              textAlign: "center !important",
              fontWeight: "bold",
              fontSize: "25px",
              overflowWrap: "break-word",
              wordWrap: "break-word",
              minHeight: "unset",
            },
            disableUnderline: true,
            readOnly: true, // Non-editable field
          }}
          sx={{
            marginBottom: 2,
            minWidth: "100%",
            "& .MuiInputBase-input": {
              textAlign: "center",
              textTransform: "capitalize",
              minHeight: "unset",
              color: setTextColor(selectedColor),
            },
          }}
        />
        <div
          style={{
            height: "82vh",
            overflowY: "scroll",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          {selectedTags && (
            <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
              {selectedTags.map((tag, index) => (
                <Chip
                  key={tag._id}
                  index={index}
                  label={`#${tag.title}`}
                  size="small"
                  sx={{
                    backgroundColor: setChipColor(selectedColor),
                    "& .MuiChip-label": {
                      color: setTextColor(selectedColor),
                    },
                  }}
                />
              ))}
            </div>
          )}
          <ReactQuill
            value={note?.details}
            readOnly={true} // Non-editable Quill editor
            theme="bubble" // Use a simpler theme without the toolbar
            style={{
              marginTop: "10px",
              height: "300px",
              marginBottom: "16px",
              //   border: "1px solid black",
              borderRadius: "4px",
              color: setTextColor(selectedColor),
            }}
          />
          <Box sx={{ display: "flex", overflowX: "auto", gap: 2, mb: 2 }}>
            {imageList?.map((attachment, index) => (
              <img
                key={attachment?._id}
                src={attachment?.url}
                alt={attachment?._id}
                onClick={() =>{ 
                  setSelectedImageIndex(index)
                  setIsViewOpen(true)
                }} // Set the selected index on click
                style={{
                  width: "150px",
                  height: "auto",
                  borderRadius: "10px",
                  aspectRatio: 1,
                  objectFit: "cover",
                  cursor: "pointer",
                }}
              />
            ))}
          </Box>
          {isViewOpen && (
            <div
              onClick={() => setIsViewOpen(false)} // Close the modal on click outside
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
                overflow: "hidden",
              }}
            >
              <div
                onClick={(e) => e.stopPropagation()} // Prevent closing the modal when clicking inside the modal content
                style={{
                  position: "relative",
                  width: "90%",
                  height: "90%",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={() => setIsViewOpen(false)}
                  aria-label="close"
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    backgroundColor: "transparent",
                  }}
                >
                  <CloseIcon />
                </IconButton>

                <PreviewImages fileList={imageList} currentIndex={selectedImageIndex} />
              </div>
            </div>
          )}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 2 }}>
            {existingAttachments
              ?.filter((att) => att.type === "audio")
              ?.map((attachment, index) => (
                <Card key={attachment._id} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <AudiotrackIcon style={{ marginLeft: "10px" }} />
                  <audio controls style={{ width: "100%" }}>
                    <source src={attachment.url} type={attachment.mimeType} />
                    Your browser does not support the audio element.
                  </audio>
                </Card>
              ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              overflowX: "auto",
              gap: 2,
              paddingBottom: 0.5,
            }}
          >
            {existingAttachments
              ?.filter((att) => !att.type.includes("image") && !att.type.includes("audio"))
              ?.map((attachment, index) => (
                <Box
                  key={attachment._id}
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    height: 50,
                    maxWidth: 200,
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "0 10px",
                    boxSizing: "border-box",
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  {renderFileIcon(attachment.fileName.split(".").pop())}
                  <Box
                    sx={{
                      ml: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      flexGrow: 1,
                      color: "#000",
                    }}
                  >
                    {truncateFileName(attachment.fileName)}
                  </Box>
                </Box>
              ))}
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default ViewPublicNote;
